import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import EventCards from '../../../components/EventCards'
import NoResultsText from '../../../components/NoResultsText'

dayjs.extend(advancedFormat)

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;
`

const DayGroup = styled.div`
  @media screen and (max-width: ${(props) => props.theme.responsive.medium}) {
    & + & .section-title {
      margin-top: 40px;
    }
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .section-title {
      margin-bottom: 30px;
    }
  }
`

const Schedule = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCalendarEvent(
        filter: { calendar: { eq: "Virtual Meeting" } }
        sort: { fields: [startTime], order: [ASC] }
      ) {
        edges {
          node {
            title
            id
            body {
              childMarkdownRemark {
                html
              }
            }
            startTime
            endTime
          }
        }
      }
    }
  `)

  const contentfulEvents = data.allContentfulCalendarEvent.edges

  const sortEventsByDay = (events) => {
    const sortedEvents = {}

    for (const event of events) {
      const date = dayjs(event.node.startTime)
      const key = `${date.day()}${date.month()}`
      if (!sortedEvents[key]) {
        sortedEvents[key] = []
      }
      sortedEvents[key].push(event)
    }

    const eventsByDay = []
    for (const events of Object.values(sortedEvents)) {
      const eventObj = {
        dateHeading: dayjs(events[0].node.startTime).format('dddd, MMMM Do'),
        events: events.map((event) => {
          event.node.startTime = dayjs(event.node.startTime).format()
          return event
        }),
      }
      eventsByDay.push(eventObj)
    }
    return eventsByDay
  }

  const eventsByDay = sortEventsByDay(contentfulEvents)
  const totalEventsByDay = eventsByDay.length

  return (
    <Wrapper className="wrap section">
      {totalEventsByDay > 0 ? (
        eventsByDay.map((dayOfEvents, index) => {
          return (
            <DayGroup
              className={index === 0 ? null : 'section'}
              key={`${dayOfEvents.events[0].id}${index}`}
            >
              <h3 className="headline--reunion center-align">
                {dayOfEvents.dateHeading}
              </h3>

              <EventCards
                events={dayOfEvents.events}
                agendaEvents="true"
                reunion="true"
                showMonth={false}
                timeZone=""
              />
            </DayGroup>
          )
        })
      ) : (
        <NoResultsText>Agenda details coming soon</NoResultsText>
      )}
    </Wrapper>
  )
}

export default Schedule
